import classNames from "classnames"
import Block from "@/components/wrappers/block"
import styles from "./paragraph.module.css"
import { getTextEffectClasses } from "@/lib/helpers/utils"

function Paragraph({ block, settings }) {
  const { layout } = block.attrs
  const classes = [
    styles.paragraph,
    block.attrs?.theme,
    block.attrs.classList,
    settings?.innerAlign
  ]

  let width = settings?.width ?? "column"
  width = layout && layout === "2-col" ? "full" : width

  let gap = "content"

  if (block.attrs?.noStyling) {
    width = gap = null
  }

  return (
    <Block
      block={block}
      width={width}
      gap={gap}
      noStyling={block.attrs.noStyling}
      className={classNames(
        classes,
        getTextEffectClasses(block?.attrs?.effect),
        {
          "layout-2-col": layout === "2-col"
        }
      )}
      innerHtml={block.html}
    />
  )
}

export default Paragraph
